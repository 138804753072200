import { CategoriesActionTypes } from 'redux/types/CategoriesTypes';
import { ProductsActionTypes } from 'redux/types/ProductsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'categories',
  modalList: [],
  modalListIsEmpty: [],
  isFetching: true,
  isFetchingToggleActive: false,
  isSubmitting: false,

  categories: null,
  categoriesProducts: {},
  categoriesProductsSearch: null,
  category: null,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case CategoriesActionTypes.SET_FETCHING_CATEGORIES:
    return {
      ...state,
      isFetching: action.payload,
    };

  case CategoriesActionTypes.UPDATE_CATEGORY_PRODUCTS:
    return {
      ...state,
      categoriesProducts: state.categoriesProducts
        ? Array.isArray(state.categoriesProducts[action.payload.categoryId])
          ? {
            ...state.categoriesProducts,
            [action.payload.categoryId]: [
              ...state.categoriesProducts[action.payload.categoryId],
              ...action.payload.products,
            ],
          }
          : {
            ...state.categoriesProducts,
            [action.payload.categoryId]: action.payload.products,
          }
        : { [action.payload.categoryId]: action.payload.products },
    };

  case CategoriesActionTypes.UPDATE_CATEGORY_PRODUCTS_SEARCH:
    return {
      ...state,
      categoriesProductsSearch: [
        ...(state.categoriesProductsSearch || []),
        ...action.payload,
      ],
    };

  case CategoriesActionTypes.CLEAR_CATEGORY_PRODUCTS_SEARCH:
    return {
      ...state,
      categoriesProductsSearch: null,
    };

  case CategoriesActionTypes.CLEAR_CATEGORIES_PRODUCTS:
    return {
      ...state,
      categoriesProducts: {},
    };

  case CategoriesActionTypes.FETCH_CATEGORIES_MODAL_SUCCESS:
    return {
      ...state,
      modalList: action.payload,
    };

  case CategoriesActionTypes.SET_CATEGORIES_MODAL_IS_EMPTY:
    return {
      ...state,
      modalListIsEmpty: action.payload,
    };

  case CategoriesActionTypes.CLEAR_CATEGORIES_MODAL:
    return {
      ...state,
      modalList: [],
      modalListIsEmpty: false,
    };

  case CategoriesActionTypes.FETCH_CATEGORIES_SUCCESS:
    return {
      ...state,
      categories: action.payload.sort((a, b) => a.position - b.position),
    };

  case CategoriesActionTypes.SET_CATEGORY:
  case CategoriesActionTypes.FETCH_GET_CATEGORY_BY_ID_SUCCESS:
    return {
      ...state,
      category: action.payload,
    };

  case CategoriesActionTypes.SET_GLOBAL_CATEGORY:
  case CategoriesActionTypes.FETCH_GET_GLOBAL_CATEGORY_BY_ID_SUCCESS:
    return {
      ...state,
      globalCategory: action.payload || null,
    };

  case CategoriesActionTypes.CLEAR_GLOBAL_CATEGORY:
    return {
      ...state,
      globalCategory: null,
    };

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: {
    const categoryProducts = (state.categoriesProducts || {})[action.payload.category.id];

    if (Array.isArray(categoryProducts)) {
      return {
        ...state,
        categoriesProducts: {
          ...state.categoriesProducts,
          [action.payload.category.id]: categoryProducts.map((p) => (p.id === action.payload.id
            ? action.payload
            : p)),
        },
      };
    }

    return state;
  }

  case CategoriesActionTypes.FETCH_CATEGORY_CREATE_SUCCESS: {
    return {
      ...state,
      categories: Array.isArray(state.categories)
        ? [
          ...state.categories,
          action.payload,
        ]
          .sort((a, b) => {
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;

            return -1;
          })
        : [ action.payload ],
    };
  }

  case CategoriesActionTypes.FETCH_CATEGORY_UPDATE_SUCCESS: {
    return {
      ...state,
      categories: state.categories.map((c) => (c.id === action.payload.id
        ? action.payload
        : c))
        .sort((a, b) => a.position - b.position),
    };
  }

  case CategoriesActionTypes.FETCH_CATEGORY_DELETE_SUCCESS: {
    if (Array.isArray(state.categories)) {
      return {
        ...state,
        categories: state.categories.filter((c) => c.id !== action.payload.categoryId),
      };
    }

    return state;
  }

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS: {
    if (Array.isArray(state.categories)) {
      return {
        ...state,
        categories: state.categories.map(((c) => (c.id === action.payload.id
          ? action.payload
          : c))),
      };
    }

    return state;
  }

  case ProductsActionTypes.FETCH_PRODUCT_UPDATE_SUCCESS: {
    const {
      product,
      oldProduct,
    } = action.payload;
    let newCategoriesProducts = { ...(state.categoriesProducts || {}) };
    let categories = state.categories || [];

    const isMove = product.category.id !== oldProduct.category.id;

    if (isMove) {
      const oldCategoryProducts = newCategoriesProducts[oldProduct.category.id] || null;
      const oldCategory = categories.find((c) => c.id === oldProduct.category.id);

      if (Array.isArray(oldCategoryProducts)) {
        newCategoriesProducts = {
          ...newCategoriesProducts,
          [oldProduct.category.id]: oldCategoryProducts.filter((p) => p.id !== oldProduct.id),
        };
      }

      if (oldCategory) {
        categories = categories.map((c) => (c.id === oldProduct.category.id
          ? {
            ...c,
            products_count: c.products_count - 1,
          } : c));
      }
    }

    const newCategoryProducts = newCategoriesProducts[product.category.id];
    const newCategory = categories.find((c) => c.id === product.category.id);

    if (Array.isArray(newCategoryProducts)) {
      newCategoriesProducts = {
        ...newCategoriesProducts,
        [product.category.id]: isMove ? [
          ...newCategoryProducts,
          product,
        ] : newCategoryProducts.map((p) => (p.id === product.id
          ? product
          : p)),
      };
    }

    if (newCategory) {
      categories = categories.map((c) => (c.id === product.category.id
        ? {
          ...c,
          products_count: c.products_count + 1,
        } : c));
    }

    return {
      ...state,
      categoriesProducts: newCategoriesProducts,
      categories,
    };
  }

  case ProductsActionTypes.FETCH_PRODUCT_DELETE_SUCCESS: {
    const categoriesProducts = (state.categoriesProducts || {});
    const categoryProducts = categoriesProducts[action.payload.category.id];

    if (categoriesProducts && categoryProducts) {
      return {
        ...state,
        categoriesProducts: {
          ...categoriesProducts,
          [action.payload.category.id]: (categoryProducts || []).filter((p) => p.id !== action.payload.id),
        },
        categories: state.categories.map((c) => (c.id === action.payload.category.id
          ? {
            ...c,
            products_count: c.products_count - 1,
          } : c)),
      };
    }

    return state;
  }

  case ProductsActionTypes.FETCH_PRODUCT_CREATE_SUCCESS: {
    const categoryId = action.payload.category.id;

    if (categoryId && state.categoriesProducts) {
      return {
        ...state,
        categoriesProducts: {
          ...state.categoriesProducts,
          [categoryId]: [
            ...(state.categoriesProducts[categoryId] || []),
            action.payload,
          ],
        },
        categories: state.categories.map((c) => (c.id === categoryId
          ? {
            ...c,
            products_count: c.products_count + 1,
          } : c)),
      };
    }

    return state;
  }

  default:
    return state;
  }
};

export default categoriesReducer;
