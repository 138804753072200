import isValidProp from '@emotion/is-prop-valid';
import React, {
  useState,
  useEffect,
} from 'react';
import { useDropzone } from 'react-dropzone';
import s, { StyleSheetManager } from 'styled-components';
import VideoPreview from '../VideoPreview';
import { IconWrapper } from 'components/base';
import { ButtonRemove } from 'components/buttons';
import { Text } from 'components/typo';
import { ReactComponent as IconPlus } from 'assets/icons/plus-big.svg';
import { checkCorrectUrl } from 'helpers/System';
import { IMAGE_MIME } from 'constants/MediaTypes';

const getColor = ({
  hover,
  theme,
  isDragReject,
  isDragActive,
  isDragAccept,
}) => {
  if (isDragAccept || hover || isDragActive) {
    return theme.colors.green;
  }
  if (isDragReject) {
    return theme.colors.red;
  }

  return theme.colors.grey300;
};

const styles = (props) => {
  const {
    theme,
    $size,
    $preview,
  } = props;
  const btnTheme = theme.components.dropzone;
  const sizes = btnTheme.sizes[$size] || btnTheme.sizes.square;

  return `
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${sizes.width};
    height: ${sizes.height};
    padding: 20px;
    color: ${getColor(props)};
    background-color: ${theme.colors.white};
    border-color: ${getColor(props)};
    border-style: dashed;
    border-width: 3px;
    border-radius: ${sizes.br};
    outline: none;
    cursor: pointer;
    transition: border 0.3s ease-in-out;

    .w-dropzone-clear {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: ${sizes.clear ? sizes.clear.top : '-10px'};
      right: ${sizes.clear ? sizes.clear.right : '-10px'};
      z-index: 10;
      background-color: ${theme.colors.grey200};
      border: 2px solid #fff;
      border-radius: 50%;
    }

    ${$preview ? `
      background: no-repeat url(${checkCorrectUrl($preview)}) center/cover;
      border: 1px solid ${theme.colors.grey300};
    ` : ''}
  `;
};

const DropzoneContainer = s.div`
  ${(props) => styles(props)}
`;

const Dropzone = ({
  id = null,
  onUpload = () => {},
  onClear = null,
  accept = IMAGE_MIME,
  defaultPreview = null,
  defaultPreviewVideo = null,
  inputRef,
  description = '',
  size,
  ...otherProps
}) => {
  const [
    isHover,
    setHover,
  ] = useState(false);
  const [
    file,
    setFile,
  ] = useState(null);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop: (acceptedFiles) => {
      setFile(
        Object.assign(acceptedFiles[0], { preview: URL.createObjectURL(acceptedFiles[0]) }),
      );
    },
  });

  useEffect(() => {
    if (file) {
      onUpload(file);
    }
    // eslint-disable-next-line
  }, [ file ])

  useEffect(() => {
    if (file && !defaultPreview) {
      setFile(null);
    }
    // eslint-disable-next-line
  }, [ defaultPreview ])

  const handleMouseEnter = () => setHover(true);

  const handleMouseLeave = () => setHover(false);

  const handleClearImage = (e) => {
    e.stopPropagation();
    setFile(null);

    if (onClear) {
      onClear();
    }
  };

  return (
    <div
      className="w-dropzone"
      id={id}
    >
      <StyleSheetManager shouldForwardProp={isValidProp}>
        <DropzoneContainer
          hover={isHover}
          $preview={file ? file.preview : defaultPreview}
          $size={size}
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
          })}
          className="w-dropzone-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...otherProps}
        >
          {file && file.type.includes('video') && (
            <VideoPreview file={file} />
          )}
          {!file && defaultPreviewVideo && (
            <VideoPreview file={{ preview: defaultPreviewVideo }} />
          )}
          <input
            {...getInputProps()}
            ref={inputRef}
          />
          {onClear && !!(file || defaultPreview || defaultPreviewVideo) && (
            <div
              className="w-dropzone-clear"
              onClick={handleClearImage}
            >
              <ButtonRemove />
            </div>
          )}
          {!file && !defaultPreview && !defaultPreviewVideo && (
            <IconWrapper
              bgColor={isDragActive || isHover ? 'greent40' : 'grey500t40'}
              fill={isDragActive || isHover ? 'green' : 'grey500'}
            >
              <IconPlus />
            </IconWrapper>
          )}

        </DropzoneContainer>
      </StyleSheetManager>
      {description && (
        <Text
          className="w-dropzone-description mt-3"
          color="grey500"
          size="small"
        >
          {description}
        </Text>
      )}
    </div>
  );
};

export default Dropzone;
