import React, {
  lazy,
  useEffect,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import * as PathsTypes from 'constants/PathsTypes';

// components
import {
  ModalWrapper,
  Notifier,
} from 'components/base';
import SuspenseContainer from 'components/base/SuspenseContainer';
import { setBrowserScrollWidth } from 'redux/actions/SystemActions';
import StorageService from 'services/StorageService';
import { getScrollbarWidth } from 'utils';

// styles
import './App.scss';

const PoliticPage = lazy(() => import('pages/PoliticPage'));
const LicensePage = lazy(() => import('pages/LicensePage'));
const Interface = lazy(() => import('layout/Interface/Interface'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const AuthPage = lazy(() => import('pages/AuthPage'));

// eslint-disable-next-line
function PrivateRoute({ children, auth }) {
  const location = useLocation();

  if (!auth) {
    return (
      <Navigate
        replace
        to={{
          pathname: '/',
          state: {
            isNeedAuth: true,
            from: location.pathname,
            search: location.search,
          },
        }}
      />
    );
  }

  return children;
}

function clearHistoryOnRefreshPage(navigate, location) {
  if (location.state && (location.state.noFetch || location.state.from || location.state.prevScrollPosition)) {
    const state = { ...location.state };

    delete state.noFetch;
    delete state.from;
    delete state.prevScrollPosition;

    navigate({
      state: {
        ...location,
        state,
      },
      replace: true,
    });
  }
}

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    clearHistoryOnRefreshPage(navigate, location);
    dispatch(setBrowserScrollWidth(getScrollbarWidth()));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    StorageService.setUserSession(profile);
    // eslint-disable-next-line
  }, [profile]);

  return (
    <div className="app">
      <SuspenseContainer>
        <Routes>
          <Route
            exact
            element={<AuthPage />}
            path={PathsTypes.HOME}
          />
          <Route
            exact
            element={<PoliticPage />}
            path={PathsTypes.POLITIC}
          />
          <Route
            exact
            element={<LicensePage />}
            path={PathsTypes.LICENSE}
          />
          <Route
            element={(
              <PrivateRoute
                auth={profile}
                path={PathsTypes.INTERFACE}
              >
                <Interface />
              </PrivateRoute>
            )}
            path={`${PathsTypes.INTERFACE}/*`}
          />
          <Route
            element={<ErrorPage title="👽&nbsp;Страница не&nbsp;найдена..." />}
          />
        </Routes>
      </SuspenseContainer>
      <Notifier />
      <ModalWrapper />
    </div>
  );
};

export default App;
