export const CategoriesActionTypes = {
  SET_FETCHING_GLOBAL_CATEGORIES: 'SET_FETCHING_GLOBAL_CATEGORIES',
  FETCH_GLOBAL_CATEGORIES_ERROR: 'FETCH_GLOBAL_CATEGORIES_ERROR',

  // CLEAR_CATEGORIES_MODAL: 'CLEAR_CATEGORIES_MODAL',
  // SET_CATEGORIES_MODAL_IS_EMPTY: 'SET_CATEGORIES_MODAL_IS_EMPTY',
  // FETCH_CATEGORIES_MODAL_SUCCESS: 'FETCH_CATEGORIES_MODAL_SUCCESS',

  SET_FETCHING_CATEGORIES: 'SET_FETCHING_CATEGORIES',
  FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR',

  CLEAR_CATEGORIES_HOME: 'CLEAR_CATEGORIES_HOME',
  SET_CATEGORIES_HOME_IS_EMPTY: 'SET_CATEGORIES_HOME_IS_EMPTY',
  FETCH_CATEGORIES_HOME_SUCCESS: 'FETCH_CATEGORIES_HOME_SUCCESS',

  FETCH_ALL_GLOBAL_CATEGORIES_SUCCESS: 'FETCH_ALL_GLOBAL_CATEGORIES_SUCCESS',
  FETCH_ALL_SUBCATEGORIES_SUCCESS: 'FETCH_ALL_SUBCATEGORIES_SUCCESS',

  CLEAR_CATEGORIES_MODAL: 'CLEAR_CATEGORIES_MODAL',
  SET_CATEGORIES_MODAL_IS_EMPTY: 'SET_CATEGORIES_MODAL_IS_EMPTY',
  FETCH_CATEGORIES_MODAL_SUCCESS: 'FETCH_CATEGORIES_MODAL_SUCCESS',

  CLEAR_GLOBAL_CATEGORIES_ALL: 'CLEAR_GLOBAL_CATEGORIES_ALL',
  CLEAR_CATEGORIES_ALL: 'CLEAR_CATEGORIES_ALL',
  CLEAR_CATEGORIES_PRODUCTS: 'CLEAR_CATEGORIES_PRODUCTS',
  SET_GLOBAL_CATEGORIES_ALL_IS_EMPTY: 'SET_GLOBAL_CATEGORIES_ALL_IS_EMPTY',
  SET_CATEGORIES_ALL_IS_EMPTY: 'SET_CATEGORIES_ALL_IS_EMPTY',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_GLOBAL_CATEGORIES_ALL_SUCCESS: 'FETCH_GLOBAL_CATEGORIES_ALL_SUCCESS',

  SET_FETCHING_CATEGORY: 'SET_FETCHING_CATEGORY',
  FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_ERROR: 'FETCH_CATEGORY_ERROR',

  SET_FETCHING_GLOBAL_CATEGORY: 'SET_FETCHING_GLOBAL_CATEGORY',
  FETCH_GLOBAL_CATEGORY_SUCCESS: 'FETCH_GLOBAL_CATEGORY_SUCCESS',
  FETCH_GLOBAL_CATEGORY_ERROR: 'FETCH_GLOBAL_CATEGORY_ERROR',

  SET_FETCHING_GLOBAL_CATEGORY_SUBCATEGORIES: 'SET_FETCHING_GLOBAL_CATEGORY_SUBCATEGORIES',
  FETCH_GLOBAL_CATEGORY_SUBCATEGORIES_SUCCESS: 'FETCH_GLOBAL_CATEGORY_SUBCATEGORIES_SUCCESS',
  FETCH_GLOBAL_CATEGORY_SUBCATEGORIES_ERROR: 'FETCH_GLOBAL_CATEGORY_SUBCATEGORIES_ERROR',

  SET_FETCHING_GET_CATEGORY_BY_ID: 'SET_FETCHING_GET_CATEGORY_BY_ID',
  FETCH_GET_CATEGORY_BY_ID_SUCCESS: 'FETCH_GET_CATEGORY_BY_ID_SUCCESS',

  SET_FETCHING_GET_GLOBAL_CATEGORY_BY_ID: 'SET_FETCHING_GET_GLOBAL_CATEGORY_BY_ID',
  FETCH_GET_GLOBAL_CATEGORY_BY_ID_SUCCESS: 'FETCH_GET_GLOBAL_CATEGORY_BY_ID_SUCCESS',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_GLOBAL_CATEGORY: 'SET_GLOBAL_CATEGORY',
  SET_GLOBAL_CATEGORY_SUBCATEGORIES: 'SET_GLOBAL_CATEGORY_SUBCATEGORIES',
  CLEAR_GLOBAL_CATEGORY: 'CLEAR_GLOBAL_CATEGORY',

  SET_FETCHING_CATEGORY_SUBMITTING: 'SET_FETCHING_CATEGORY_SUBMITTING',
  SET_FETCHING_GLOBAL_CATEGORY_SUBMITTING: 'SET_FETCHING_GLOBAL_CATEGORY_SUBMITTING',
  SET_FETCHING_CATEGORY_TOGGLE_ACTIVE: 'SET_FETCHING_CATEGORY_TOGGLE_ACTIVE',

  FETCH_CATEGORY_CREATE_SUCCESS: 'FETCH_CATEGORY_CREATE_SUCCESS',
  FETCH_CATEGORY_UPDATE_SUCCESS: 'FETCH_CATEGORY_UPDATE_SUCCESS',
  FETCH_CATEGORY_DELETE_SUCCESS: 'FETCH_CATEGORY_DELETE_SUCCESS',
  FETCH_GLOBAL_CATEGORY_CREATE_SUCCESS: 'FETCH_GLOBAL_CATEGORY_CREATE_SUCCESS',
  SET_FETCHING_GLOBAL_CATEGORY_UPDATE: 'SET_FETCHING_GLOBAL_CATEGORY_UPDATE',
  FETCH_GLOBAL_CATEGORY_UPDATE_SUCCESS: 'FETCH_GLOBAL_CATEGORY_UPDATE_SUCCESS',
  FETCH_GLOBAL_CATEGORY_DELETE_SUCCESS: 'FETCH_GLOBAL_CATEGORY_DELETE_SUCCESS',
  FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS: 'FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS',
  SET_FETCHING_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: 'SET_FETCHING_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS',
  FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: 'FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS',
  UPDATE_CATEGORY_PRODUCTS: 'UPDATE_CATEGORY_PRODUCTS',
  UPDATE_CATEGORY_PRODUCTS_SEARCH: 'UPDATE_CATEGORY_PRODUCTS_SEARCH',
  CLEAR_CATEGORY_PRODUCTS_SEARCH: 'CLEAR_CATEGORY_PRODUCTS_SEARCH',
};
