import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import s from 'styled-components';
import cn from 'classnames';
import ModalConfirm from './modals/ModalConfirm';
import Spinner from './base/Spinner';
import {
  SUBSCRIPTIONS,
  PeriodTabsModel,
} from 'constants/SubscriptionsTypes';
import {
  Tabs,
  ModalCenterWrapper,
} from 'components/base';
import { Box } from 'layout';
import SubscriptionsList from 'components/Subscriptions';
import useResponsive from 'components/hooks/useResponsive';
import {
  fetchBuySubscriptionsAsync,
  fetchSubscriptions,
  setCompanySubscription,
} from 'redux/actions/SubscriptionsActions';
import { setModal } from 'redux/actions/SystemActions';
import StorageService from 'services/StorageService';

const SubscriptionsBlockContainer = s.div`
  .subscriptions__tabs {
    @media (max-width: 768px) {
      max-width: 500px;
      margin: 0 auto;
    }
  }
`;

const SubscriptionsBlock = ({
  children,
  withHover = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const {
    list: subscriptions,
    isFetchingBuy: isFetchingSubscription,
  } = useSelector((state) => state.subscriptions);
  const { profile } = useSelector((state) => state.user);
  const [
    activeTab,
    setActiveTab,
  ] = useState(PeriodTabsModel[1].id);
  const [
    showConfirmModal,
    setShowConfirmModal,
  ] = useState(false);
  const [
    buyPeriodId,
    setBuyPeriodId,
  ] = useState(false);
  const { isLaptopOrMobile } = useResponsive();

  const isAuth = !!profile;

  const onToggle = useCallback((activeId) => {
    if (isAuth) {
      dispatch(setModal({
        name: 'order-subscription',
        data: { id: activeId },
      }));
    }
    else {
      dispatch(setModal({ name: 'signup' }));
    }
  }, [
    isAuth,
    dispatch,
  ]);

  useEffect(() => {
    if (!subscriptions.length) {
      dispatch(fetchSubscriptions());
    }

    if (profile && profile.company && profile.company.active_subscription && profile.company.active_subscription.rate_period.period) {
      setActiveTab(profile.company.active_subscription.rate_period.period);
    }

    // eslint-disable-next-line
  }, [])

  const handleSubscription = useCallback((id) => {
    setActiveTab(id);
  }, [ setActiveTab ]);

  const userSubscription = useMemo(() => {
    if (profile && profile.company && profile.company.active_subscription) {
      return {
        ended_at: profile.company.active_subscription.ended_at,
        period: profile.company.active_subscription.rate_period.id,
        subscriptionId: profile.company.active_subscription.rate_period.rate,
      };
    }

    return {};
  }, [ profile ]);

  const mappedSubscriptions = useMemo(() => SUBSCRIPTIONS.map((subscriptionMock) => {
    const subscription = subscriptions.find((s) => s.id === subscriptionMock.id);

    if (subscription) {
      return {
        ...subscriptionMock,
        title: subscription.title,
        description: subscription.description,
        list: [ ...subscription.options.map((o) => o.title) ],
        periodsPrices: subscription.periods || [],
        info: subscription.comment || null,
        rate_type: subscription.rate_type,
      };
    }

    return subscriptionMock;
  }), [ subscriptions ]);

  const handleCloseConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
  }, [ setShowConfirmModal ]);

  const handleShowConfirmModal = useCallback((activePeriodPriceId) => {
    setShowConfirmModal(true);
    setBuyPeriodId(activePeriodPriceId);
  }, [ setShowConfirmModal ]);

  const handleBuySubscription = useCallback((ratePeriodId, isModal) => {
    const data = {
      payment_token: StorageService.getToken(),
      rate_period: ratePeriodId,
      // is_auto_renewal: false,
    };

    if (isModal) {
      handleCloseConfirmModal();
    }

    dispatch(fetchBuySubscriptionsAsync(data))
      .then((res) => {
        const { yandex_payment: yaPayment } = res;

        if (!yaPayment) {
          dispatch(setCompanySubscription(res));
        }
        else {
          const { payment_url: paymenUrl } = yaPayment;

          window.location.href = paymenUrl;
        }
      });
  }, [
    dispatch,
    handleCloseConfirmModal,
  ]);

  return (
    <SubscriptionsBlockContainer
      className={cn('subscriptions', { '--mob': isLaptopOrMobile })}
      {...props}
    >
      <Box
        className={cn('subscriptions__tabs', {
          'mb-6': !isLaptopOrMobile,
          'mb-4 --mob': isLaptopOrMobile,
        })}
        jc="center"
      >
        <Tabs
          active={activeTab}
          items={PeriodTabsModel}
          onToggle={handleSubscription}
        />
      </Box>
      <div className="subscriptions__list">
        {isFetchingSubscription || !subscriptions || subscriptions.length === 0
          ? <Spinner />
          : (
            <SubscriptionsList
              activePeriod={activeTab}
              activeSubscription={userSubscription.subscriptionId}
              activeSubscriptionEndedAt={userSubscription.ended_at}
              activeSubscriptionPeriod={userSubscription.period}
              activeUserSubscription={userSubscription}
              isAuth={isAuth}
              isFetchingSubscription={isFetchingSubscription}
              showConfirmModal={handleShowConfirmModal}
              subscriptions={mappedSubscriptions}
              withHover={withHover}
              onBuy={handleBuySubscription}
              onToggle={onToggle}
            />
          )}
      </div>
      {showConfirmModal && (
        <ModalCenterWrapper
          closeModal={handleCloseConfirmModal}
          component={ModalConfirm}
          modal={{
            onYes: () => handleBuySubscription(buyPeriodId, true),
            type: 'cancle-subscription',
          }}
          show={showConfirmModal}
        />
      )}
    </SubscriptionsBlockContainer>
  );
};

export default SubscriptionsBlock;
