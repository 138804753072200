import { combineReducers } from 'redux';
import auth from './AuthReducer';
import analytics from './AnalyticsReducer';
import catalog from './CatalogReducer';
import categories from './CategoriesReducer';
import clients from './ClientsReducer';
import demos from './DemosReducer';
import filters from './FiltersReducer';
import locations from './LocationsReducer';
import orders from './OrdersReducer';
import products from './ProductsReducer';
import user from './UserReducer';
import settings from './SettingsReducer';
import system from './SystemReducer';
import subscriptions from './SubscriptionsReducer';

const rootReducer = combineReducers({
  auth,
  analytics,
  catalog,
  categories,
  clients,
  demos,
  filters,
  locations,
  orders,
  products,
  user,
  settings,
  subscriptions,
  system,
});

export default rootReducer;
