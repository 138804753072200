export const QUANTITYS = {
  GRAM: 'GRAM',
  // LITRE: 'LITRE',
  // MILLILITRE: 'MILLILITRE',
  PIECE: 'PIECE',
  KILLOGRAMM: 'KILLOGRAMM',
};

export const PRODUCT_QUANTITYS = {
  [QUANTITYS.GRAM]: 'Грамм',
  // [QUANTITYS.LITRE]: 'Литры',
  // [QUANTITYS.MILLILITRE]: 'Миллилитры',
  [QUANTITYS.PIECE]: 'Штуки',
  [QUANTITYS.KILLOGRAMM]: 'Килограммы',
};

export const PRODUCT_QUANTITYS_SHORT = {
  [QUANTITYS.GRAM]: 'г',
  // [QUANTITYS.LITRE]: 'л',
  // [QUANTITYS.MILLILITRE]: 'мл',
  [QUANTITYS.PIECE]: 'шт',
  [QUANTITYS.KILLOGRAMM]: 'кг',
};

export const QUANTITY_UNIT_OPTIONS = Object.keys(PRODUCT_QUANTITYS)
  .map((key) => ({
    value: key,
    label: PRODUCT_QUANTITYS[key],
  }));
